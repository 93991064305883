import { CaseCreateReqReserved, E2B_R3_NullFlavor, SafetyCrfItemReserved } from "@maven-rest/safety/Crf.type";

export const generalDateTimeFormat = "YYYY/MM/DD HH:mm:ss Z";
export const generalDateFormat = "YYYY/MM/DD";

export const dbValDateFormat = "YYYYMMDD";

export const nullFlavorValuesSet: Set<String> = new Set(Object.values(E2B_R3_NullFlavor));

export const NULL_FLAVOR_ITEM_ID_SUFFIX = "NF";
export const NULL_FLAVOR_ITEM_ID_REGEX = new RegExp(`.*${NULL_FLAVOR_ITEM_ID_SUFFIX}`);
export const PLAIN_ITEM_ID_EXTRACTOR_REGEX = new RegExp(`(.+)${NULL_FLAVOR_ITEM_ID_SUFFIX}`);

export const E2B_R3_XML_IMPORT_SIMPLIFIED_REQUIRED_RESERVES: Array<CaseCreateReqReserved> = [
  SafetyCrfItemReserved.WorldwideUniqueId,
  SafetyCrfItemReserved.Resource,
  SafetyCrfItemReserved.ReactionEvent,
];
