export interface Padding {
  appSidebarNaviPaddingLeft: string;
  appContentPadding: string;
  appContentBoxPadding: string;
  appContentBoxPaddingNarrow: string;
  appFormFieldPadding: string;
}

export const padding: Padding = {
  appSidebarNaviPaddingLeft: "1rem",
  appContentPadding: "1.75rem",
  appContentBoxPadding: "1.1rem",
  appContentBoxPaddingNarrow: "1.6rem",
  appFormFieldPadding: "0.375rem 0.75rem",
};
