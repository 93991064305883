import { MvnCodeFE } from "../const";

export const SAFETY_CAPTURE_VALIDATE_LANG: {
  SAFETY_CAPTURE_VALIDATE_FAIL_DIALOG_TITLE: MvnCodeFE;
  SAFETY_CAPTURE_VALIDATE_FAIL_DIALOG_DESCRIPTION: MvnCodeFE;
  SAFETY_CAPTURE_VALIDATE_FAIL_DIALOG_HIGHLIGHT_DESCRIPTION: MvnCodeFE;
  SAFETY_CAPTURE_VALIDATE_FAIL_DIALOG_EMPTY_FIELD_LABEL: MvnCodeFE;
  SAFETY_CAPTURE_VALIDATE_FAIL_DIALOG_REQUIRED_FIELD_LABEL: MvnCodeFE;
  SAFETY_CAPTURE_VALIDATE_SAVE_FAIL_BY_REQUIRED: MvnCodeFE;
  SAFETY_CAPTURE_VALIDATE_SAVE_FAIL_BY_EMPTY_ROW: MvnCodeFE;
} = {
  SAFETY_CAPTURE_VALIDATE_FAIL_DIALOG_TITLE: {
    ko: "저장 실패",
    en: "Save Failed",
  },
  SAFETY_CAPTURE_VALIDATE_FAIL_DIALOG_DESCRIPTION: {
    ko: "다음 페이지에 누락된 값이 있습니다.",
    en: "There are missing fields on the following pages.",
  },
  SAFETY_CAPTURE_VALIDATE_FAIL_DIALOG_HIGHLIGHT_DESCRIPTION: {
    ko: "누락된 값을 모두 채워주세요.",
    en: "Please fill the missing fields.",
  },
  SAFETY_CAPTURE_VALIDATE_FAIL_DIALOG_EMPTY_FIELD_LABEL: {
    ko: "빈 행",
    en: "Empty Fields",
  },
  SAFETY_CAPTURE_VALIDATE_FAIL_DIALOG_REQUIRED_FIELD_LABEL: {
    ko: "필수값 누락",
    en: "Required Fields",
  },
  SAFETY_CAPTURE_VALIDATE_SAVE_FAIL_BY_REQUIRED: {
    ko: "{section} > No.{seq} > {item} 값이 입력되지 않았습니다.",
    en: "The value for {item} > No.{seq} in the {section} is missing.",
  },
  SAFETY_CAPTURE_VALIDATE_SAVE_FAIL_BY_EMPTY_ROW: {
    ko: "{section}에 빈 행이 존재합니다.",
    en: "There is an empty row in the {section}.",
  },
};
